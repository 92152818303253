import React from "react";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a className="fw-600" href="/">
                {t('common.nameGroup')}
              </a>
              .  {t('common.allRightsReserved')}.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
