import React from "react";
import resumeFile from "../documents/champeval-maxime-cv.pdf";
import {useTranslation} from "react-i18next";

const Resume = () => {
  const { t } = useTranslation();
  const educationDetails = [
    {
      yearRange: t('resume.pro.1.year'),
      stack: t('resume.pro.1.stack'),
      title: t('resume.pro.1.title'),
      place: t('resume.pro.1.place'),
      desc: t('resume.pro.1.desc'),
    },
    {
      yearRange: t('resume.pro.3.year'),
      stack: t('resume.pro.3.stack'),
      title: t('resume.pro.3.title'),
      place: t('resume.pro.3.place'),
      desc: t('resume.pro.3.desc'),
    },
    {
      yearRange: t('resume.pro.4.year'),
      stack: t('resume.pro.4.stack'),
      title: t('resume.pro.4.title'),
      place: t('resume.pro.4.place'),
      desc: t('resume.pro.4.desc'),
    },
    {
      yearRange: t('resume.pro.5.year'),
      stack: t('resume.pro.5.stack'),
      title: t('resume.pro.5.title'),
      place: t('resume.pro.5.place'),
      desc: t('resume.pro.5.desc'),
    },
  ];

  const experienceDetails = [
    {
      yearRange: t('resume.personal.1.year'),
      stack: t('resume.personal.1.stack'),
      title: t('resume.personal.1.title'),
      desc: t('resume.personal.1.desc'),
    },
    {
      yearRange: t('resume.personal.2.year'),
      stack: t('resume.personal.2.stack'),
      title: t('resume.personal.2.title'),
      desc: t('resume.personal.2.desc'),
    },
    {
      yearRange: t('resume.personal.3.year'),
      stack: t('resume.personal.3.stack'),
      title: t('resume.personal.3.title'),
      desc: t('resume.personal.3.desc'),
    },
  ];

  const skills = [
    {
      name: "ReactJs",
      percent: 95,
    },
    {
      name: "NextJS",
      percent: 95,
    },
    {
      name: "Ionic",
      percent: 95,
    },
    {
      name: "Typescript",
      percent: 95,
    },
    {
      name: "Javascript",
      percent: 95,
    },
    {
      name: "NodeJs/NestJs",
      percent: 90,
    },
    {
      name: "MongoDB/Postgresql/Sql",
      percent: 90,
    },
    {
      name: "Tailwind/Bootstrap",
      percent: 99,
    },
    {
      name: "Symfony",
      percent: 90,
    },
    {
      name: "Git",
      percent: 90,
    },
    {
      name: "Docker",
      percent: 80,
    },
    {
      name: "GithubAction",
      percent: 80,
    },
    {
      name: "AWS",
      percent: 80,
    },
    {
      name: "Google Cloud",
      percent: 70,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-white px-2">{t('resume.resume')}</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('resume.summaryResume')}
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">{t('resume.experiencePro')}</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                    <p className="mb-2">
                      {value.stack}
                    </p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">{t('resume.experiencePersonal')}</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                    <p className="mb-2">
                      {value.stack}
                    </p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">{t('resume.mySkills')}</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
  {/*      <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            {t('resume.downloadResume')}
          </a>
        </p>*/}
      </div>
    </section>
  );
};

export default Resume;
