import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const StandardMenuHeader = () => {
  const { t } = useTranslation();
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
  }
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
        <div className="container position-relative">
          <div className="col-auto col-lg-2 d-inline-flex ps-lg-0">
            {/* Logo */}
            <Link
              className="logo"
              title="Callum"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer", color: "black", textDecoration: "none", fontSize: "18px" }}
              offset={-72}
              to="home"
              onClick={(e) => {
                e.preventDefault();
                setIsNavModalClose(true);
              }}
            >
              Izo Web Development
            </Link>
            {/* Logo End */}
          </div>
          <div className="col col-lg-8 navbar-accordion px-0">
            <button
              className={
                "navbar-toggler ms-auto collapsed " +
                (isNavModalClose ? "" : "show")
              }
              type="button"
              onClick={() => setIsNavModalClose(!isNavModalClose)}
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                "collapse navbar-collapse justify-content-center " +
                (isNavModalClose ? "" : "show")
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="home"
                    offset={-71}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.home')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.about')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.services')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="resume"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.resume')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="portfolio"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.portfolio')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="contact"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    {t('menu.contact')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 d-flex justify-content-end ps-0">
            <ul className="social-icons">
              <li className="social-icons-facebook">
                <Tooltip text={t('menu.french')} placement="bottom">
                  <a onClick={() => changeLanguage('fr')} role="button">
                    FR
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-twitter">
                <Tooltip text={t('menu.english')} placement="bottom">
                  <a onClick={() => changeLanguage('en')} role="button">
                    EN
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default StandardMenuHeader;
