import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import {useTranslation} from "react-i18next";
const Portfolio = () => {
  const { t } = useTranslation();
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    PERSONNEL: t('portfolio.personnel'),
    PROFESSIONALS: t('portfolio.professionals'),
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Ginto",
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.pro.2.descLong'),
        client: t('resume.pro.2.place'),
        technologies: t('resume.pro.2.stack'),
        sliderImages: [
          "images/projects/ginto/2.png",
          "images/projects/ginto/3.png"
        ],
      },

      thumbImage: "images/projects/ginto/1.png",

      categories: [filters.PROFESSIONALS],
    },
    {
      title: "Circular City",
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.pro.3.descLong'),
        client: t('resume.pro.3.place'),
        technologies: t('resume.pro.3.stack'),
        sliderImages: [
          "images/projects/circular-city/2.png",
          "images/projects/circular-city/3.png"
        ],
      },

      thumbImage: "images/projects/circular-city/1.png",

      categories: [filters.PROFESSIONALS],
    },
    {
      title: t('resume.pro.4.place'),
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.pro.4.descLong'),
        client: t('resume.pro.4.place'),
        technologies: t('resume.pro.4.stack'),
        url: {
          name: "app.indigo.world",
          link: "https://app.indigo.world/",
        },

        sliderImages: [
          "images/projects/indigo/1.png",
          "images/projects/indigo/2.png"
        ],
      },
      thumbImage: "images/projects/indigo/cover.png",
      categories: [filters.PROFESSIONALS],
    },
    {
      title: t('resume.pro.4.place'),
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.pro.5.descLong'),
        client: t('resume.pro.5.place'),
        technologies: t('resume.pro.5.stack'),
        url: {
          name: "mykiddybox.com",
          link: "https://www.mykiddybox.com/",
        },

        sliderImages: [
          "images/projects/cshort/2.png",
          "images/projects/cshort/3.png"
        ],
      },
      thumbImage: "images/projects/cshort/1.png",
      categories: [filters.PROFESSIONALS],
    },
    {
      title: "Projet Quiz",
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.personal.1.descLong'),
        client: t('resume.personal.1.place'),
        technologies: t('resume.personal.1.stack'),

        sliderImages: [
          "images/projects/quiz/1.png",
          "images/projects/quiz/2.png",
          "images/projects/quiz/3.png"
        ],
      },
      thumbImage: "images/projects/quiz/cover.png",
      categories: [filters.PERSONNEL],
    },
    {
      title: "Perfect Scans",
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.personal.2.descLong'),
        client: t('resume.personal.2.place'),
        technologies: t('resume.personal.2.stack'),

        sliderImages: [
          "images/projects/perfect-scans/1.png",
          "images/projects/perfect-scans/2.png",
          "images/projects/perfect-scans/3.png"
        ],
      },
      thumbImage: "images/projects/perfect-scans/cover.png",
      categories: [filters.PERSONNEL],
    },
    {
      title: "Entre Geeks",
      type: types.DOCUMENT,
      document: {
        projectInfo: t('resume.personal.3.descLong'),
        client: t('resume.personal.3.place'),
        technologies: t('resume.personal.3.stack'),
        url: {
          name: "entre-geeks.com",
          link: "https://www.entre-geeks.com/",
        },

        sliderImages: [
          "images/projects/entre-geeks/2.png",
          "images/projects/entre-geeks/3.png",
          "images/projects/entre-geeks/4.png"
        ],
      },
      thumbImage: "images/projects/entre-geeks/1.png",
      categories: [filters.PERSONNEL],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-white px-2">{t('portfolio.portfolio')}</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            {t('portfolio.recentProjects')}
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                {t('portfolio.all')}
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
