import React from "react";
import {useTranslation} from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  // services details
  const services = [
    {
      name: t('service.block1.name'),
      desc: t('service.block1.description'),
      icon: "fas fa-palette",
    },
    {
      name: t('service.block2.name'),
      desc: t('service.block2.description'),
      icon: "fas fa-desktop",
    },
    {
      name: t('service.block3.name'),
      desc: t('service.block3.description'),
      icon: "fas fa-pencil-ruler",
    },
    {
      name: t('service.block4.name'),
      desc: t('service.block4.description'),
      icon: "fas fa-paint-brush",
    },
    {
      name: t('service.block5.name'),
      desc: t('service.block5.description'),
      icon: "fas fa-chart-area",
    },
    {
      name: t('service.block6.name'),
      desc: t('service.block6.description'),
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-white px-2">{t('service.whatIDo')}</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('service.howHelpYou')}
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0 pre-line">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
